import * as React from "react"
import Layout from "../components/layout"
import { graphql } from 'gatsby'
import SEO from "../components/seo"
import Section from "../components/Section"
import {
  BlogPost,
  Row,  
  BlogGrid,
  SecondaryButton,
  MaxContainer
} from '../styles/utility'
import Location from "../components/Location"

const ResourcesPage = ({ data }) => {
  if (!data) return null
  const document = data.allPrismicPost.edges
  return (
    <Layout>
      <SEO title="Dental Patient Resources" noFollow={true} description="Read our collection of articles and resources to understand how to take care and manage your oral health." />
      <MaxContainer>
        <Row>
          <Section sectionTitle="Patient Resources">
            <BlogGrid>
              {document.map((post, index) => (
                  <BlogPost key={index}>
                    <img src={post.node.data.resource_image.url} alt={post.node.data.title.text} />
                    <h3>{post.node.data.title.text}</h3>
                    <p>{post.node.data.resource_summary.text}</p>
                    <SecondaryButton href={post.node.url}>Read More</SecondaryButton>
                  </BlogPost>
              ))}
            </BlogGrid>
          </Section>
          <Section>
            <Location />
          </Section>
        </Row>
      </MaxContainer>
    </Layout>
  )
}


export const query = graphql`
  query PatientResources {
    allPrismicPost {
      edges {
        node {
          url
          data {
            title {
              text
            }
            resource_image {
              url
            }
            resource_summary {
              text
            }
          }
        }
      }
    }
  }
`

export default ResourcesPage
